body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "dbx";
  src: local("dbx"), url(./Fonts/db_adman_x.ttf) format("truetype");
}

.font-common {
  font-family: "dbx";
  /* font-size: 72px;
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.background {
  /* background-position: center;
  background-size: cover; */
  height: 100vh;
  overflow: auto;
}

.background-login {
  height: 100vh;
  width: 100vw;
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topLeft {
  position: absolute;
  top: 0px;
  left: 0px;
}

.ant-table table {
  font-family: "dbx";
  font-size: 20px;
}

.ant-form-item {
  font-family: "dbx";
}

.ant-form-item-label > label {
  font-size: 18px !important;
}

.ant-input {
  border-radius: 25px !important;
  font-size: 18px !important;
}

.ant-select-selector input {
  border-radius: 25px !important;
  font-size: 18px !important;
}

.size-common {
  font-size: 20px !important;
  padding-top: 15px;
}

.ant-select-item-option {
  font-family: "dbx";
  font-size: 18px !important;
  border-radius: 25px !important;
}

.ant-select {
  font-family: "dbx";
  font-size: 18px !important;
  border-radius: 25px !important;
}

.ant-input-number-input {
  font-size: 18px !important;
}

.ant-tree {
  background: transparent !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 25px !important;
}

.dropdown-toggle::after {
  color: black !important;
}

.ant-btn:hover {
  background: transparent;
  border-color: transparent;
}

/* .ant-btn:focus {
  background: transparent;
  border-color: transparent;
}

.ant-btn:active {
  background: transparent !important;
  border-color: transparent !important;
  color: transparent !important;
} */

.ant-checkbox-group-item {
  display: block !important;
  margin-right: 0;
  width: 100%;
}

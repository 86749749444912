.background1 {
  /* background-color: #bcbcbc; */
  background-color: transparent;
  width: 60px;
  height: 60px;
  padding: 0;
  margin: 0;
}

.background2 {
  /* background-color: #bcbcbc; */
  background-color: transparent;
  width: 10px;
  height: 60px;
  padding: 0;
  margin: 0;
}

.line1 {
  width: 10px;
  height: 60px;
  margin-left: 0px;
  border-right: 4px solid black;
  -webkit-transform: translateY(-2px) translateX(0px) rotate(0deg);
  position: absolute;
}

.line2 {
  width: 110px;
  height: 47px;
  margin-left: -14px;
  border-bottom: 3px solid black;
  -webkit-transform: translateY(-14px) translateX(5px) rotate(0deg);
  position: absolute;
}

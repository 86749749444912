.odd {
  background: color(var(--midnight-5) alpha(50%));
  /* background-color: #123b64; */
}

.even {
  background-color: #f8f9fa;
}

.ant-table-tbody > tr > td {
  /* text-align: center !important; */
  background-color: #eaf1ff !important;
}
.ant-table-tbody > tr:nth-child(odd) > td {
  /* text-align: center !important; */
  background: #fcfdff !important;
}

* line background color*/ .ant-table-thead th {
  text-align: center !important;
  background: #eaf1ff !important;
  /* border: solid 1px red !important */
  border-top: solid 1px #226ca5 !important;
  border-bottom: solid 1px #226ca5 !important;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-filter-trigger {
  margin-right: -4px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small
  .ant-table-tbody
  .ant-table-wrapper:only-child
  .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #561ab6;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #49c916;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #8f1717;
  border-right: 0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-right: 1px solid #5a0202;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr:not(:last-child)
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr:not(:last-child)
  > th {
  border-bottom: 1px solid #000000;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th::before,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th::before,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th::before,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th::before {
  background-color: transparent !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #000000;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #000000;
  content: "";
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-expanded-row
  > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr.ant-table-placeholder
  > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #000000;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: "";
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: "";
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 2px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 2px 2px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first
  .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-cell-ellipsis .ant-table-column-title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: center !important;
  background: #fafafa;
  border-bottom: 1px solid #000000;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan="1"]) {
  text-align: center;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  transform: translateY(-50%);
  transition: background-color 0.3s;
  content: "";
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #000000;
  transition: background 0.3s;
}
.ant-table-wrapper.nested-table .ant-table-thead > tr > th {
  height: 5px;
  padding: 4px;
}
.ant-table-wrapper.nested-table table {
  margin: 4px 0px;
  border: 1px solid #444444;
}
.ant-table-wrapper.nested-table .ant-table-tbody > tr > td {
  height: 5px;
  padding: 4px;
}